import { inject }                    from 'aurelia-framework';
import { PLATFORM }                  from 'aurelia-pal';
import { BaseFormViewModel }         from 'base-form-view-model';
import { FormSchema }                from 'modules/map/mat-app-proposals/form-schema';
import { MAPFilesRepository }        from 'modules/map/mat-app-proposals/services/files-repository';
import { MatAppProposalsRepository } from 'modules/map/mat-app-proposals/services/repository';
import { AppContainer }              from 'resources/services/app-container';

@inject(AppContainer, MatAppProposalsRepository, FormSchema, MAPFilesRepository)
export class ViewMatAppProposal extends BaseFormViewModel {
    headerTitle = 'form.title.view-record';
    formId      = 'map.mat-app-proposals.view-form';

    alert  = {};
    model  = {};
    schema = {};

    readonly = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param filesRepository
     */
    constructor(appContainer, repository, formSchema, filesRepository) {
        super(appContainer);

        this.repository      = repository;
        this.formSchema      = formSchema;
        this.filesRepository = filesRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/map/mat-app-proposals/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.mat-app-proposals.index') && super.can([
            'map.mat-app-proposals.manage',
            'map.mat-app-proposals.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        if (this.model.is_validated) {
            this.info = this.infoAlertMessage('form.message.viewing-validated-record');
        }

        this.schema        = this.formSchema.schema(this, true);
        this.globalSchema  = this.formSchema.globalSchema(this, true);
        this.lotInfoSchema = this.formSchema.lotInfoSchema(this);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
